<template>
  <v-dialog v-model="dialog" max-width="750px">
    <v-card>
      <v-card-title>
        <span class="headline"> Revalorisé le produit </span>
      </v-card-title>
      <v-card-text>
        <v-card-subtitle>
          Ajouter les différentes informations nécessaires à la revalorisation
          du produit <span style="font-weight: bold">{{ name }}</span
          >.
        </v-card-subtitle>
        <v-row :no-gutters="$vuetify.breakpoint.smAndDown">
          <v-col cols="12" md="6" class="my-0 py-0">
            <!-- <v-text-field
              prepend-icon="mdi-pound"
              item-text="num_scelle"
              placeholder
              type="text"
              label="numéro de scellé"
              v-model="num_scelle"
            />
          </v-col>
          <v-col cols="12" md="6" class="my-0 py-0">
            <v-text-field
              prepend-icon="mdi-pound"
              item-text="ref"
              placeholder
              type="text"
              label="Référence"
              v-model="ref"
            /> -->
            <v-combobox
              :loading="refLoading"
              prepend-icon="mdi-pound"
              :items="productSheetsList"
              item-text="ref"
              placeholder
              type="text"
              label="Référence"
              v-model="ref"
              @keyup="getListRef($event.target.value)"
              @change="fillInputs($event)"
            />
          </v-col>
          <v-col cols="12" md="6">
            <div class="mb-2">
              <div class="d-flex my-2">
                <v-icon class="mr-2"> mdi-camera </v-icon>
                <v-card-text class="px-0 py-0" style="font-size: 16px">
                  Images
                </v-card-text>
              </div>
              <v-card
                flat
                outlined
                class="py-4 px-4 ml-7"
                style="width: fit-content"
              >
                <ImageProduct
                  ref="image"
                  size="80"
                  @removeImg="removeImg()"
                  @addImage="addImage($event)"
                />
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-2" text @click="close"> Annuler </v-btn>
        <v-btn
          color="success"
          text
          :loading="loading"
          @click="save"
          :disabled="!ref.length"
        >
          Valider
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ImageProduct from "@/components/Catalog/ImageProduct.vue";

export default {
  components: {
    ImageProduct,
  },
  props: {},
  data() {
    return {
      loading: false,
      dialog: false,
      newProductSheetBool: false,
      productSheetsList: [], // List of products sheets search by the ref starting name,
      refLoading: false,
      image: null,
      name: "",
      item: {},
      ref: "",
      num_scelle: "",
      id: "",
    };
  },
  async mounted() {},
  watch: {},
  computed: {},
  methods: {
    removeImg() {
      this.image = undefined;
      this.$refs.image.setImage(undefined);
    },
    addImage(e) {
      this.image = e;
      this.$refs.image.setImage(URL.createObjectURL(e));
    },
    fillInputs(value) {
      this.$nextTick(async () => {
        if (value && typeof value == "string") {
          this.newProductSheetBool = true;
        } else {
          this.newProductSheetBool = false;
          if (value.ref) this.ref = value.ref;
          if (value.Images) {
            const blob = await fetch(value.Images[0].url).then((r) => r.blob()); // Get blob from url
            const file = new File([blob], value.Images[0].name); // Get file from blob
            this.addImage(file);
          }
        }
      });
    },
    getListRef(value) {
      if (value) {
        this.newProductSheetBool = true;
        this.ref = value;
        this.refLoading = true;
        let additionalParams = {
          ref: value,
        };
        console.log(additionalParams);
        this.$store
          .dispatch(`productSheets/fetchAll`, {
            itemsPerPage: 5,
            page: 1,
            additionalParams,
          })
          .then((res) => {
            this.productSheetsList = {};
            this.productSheetsList = res.member;
            this.refLoading = false;
          });
      }
    },
    save() {
      this.$emit(
        "save",
        { ...this.item, ref: this.ref, num_scelle: this.num_scelle, image: this.image },
        this.id
      );
      this.close();
    },
    open(item, name, id) {
      console.log(item);
      this.name = name;
      if (item.image) {
        this.$nextTick(() => {
          this.$refs.image.setImage(URL.createObjectURL(item.image));
        });
        this.image = item.image;
      } else {
        this.$nextTick(() => {
          this.$refs.image.setImage(undefined);
        });
        this.image = null;
      }
      this.ref = item.ref ? item.ref : "";
      this.num_scelle = item.num_scelle ? item.num_scelle : "";
      this.item = item;
      this.id = id;
      this.loading = false;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>
