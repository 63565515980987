<template>
  <div class="d-flex align-center">
    <v-btn icon @click="onClick">
      <v-icon :color="color">
        {{ icon }}
      </v-icon>
    </v-btn>

    <span style="cursor: pointer; user-select: none" @click="onClick">
      {{ label }}
    </span>
  </div>
</template>

<script>
const icons = ["mdi-checkbox-blank-outline", "mdi-checkbox-marked"];
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      enabled: false,
      indeterminate: false,
      state: 0,
      color: "",
      icon: "",
    };
  },
  mounted() {
    if (this.value && this.value) {
      this.state = this.value;
    }
    this.refresh();
  },
  methods: {
    onClick() {
      this.state += 1;
      this.refresh();
      this.$emit("input", this.state);
    },
    setValue(val) {
      this.state = val;
      this.refresh();
    },
    refresh() {
      if (this.state === 3) {
        this.state = 0;
      }
      let r = [0, "primary"];
      if (this.state === 0) {
        r = [0, ""];
      }
      if (this.state === 1) {
        r = [1, "primary"];
      }
      if (this.state === 2) {
        r = [1, "purple"];
      }
      // if (this.state === 3) {
      //   r = [2, '']
      // }
      this.icon = icons[r[0]];
      this.color = r[1];
    },
  },
};
</script>
