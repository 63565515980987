<template>
  <div
    @click="onClick">
    <v-checkbox
      :indeterminate="indeterminate"
      v-model="enabled"
      class="in-fact-not-disabled"
      :label="label"
      :style="'color:' + color"
    />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      enabled: false,
      indeterminate: false,
      state: 0,
      color: 'white',
    }
  },
  mounted () {
    this.state = this.value
    this.refresh()
  },
  methods: {
    onClick () {
      this.state += 1
      this.refresh()
      this.$emit('input', this.state)
    },
    setValue(val) {
      this.state = val
      this.refresh()
    },
    refresh () {
      if (this.state === 4) {
        this.state = 0
      }
      let r = [false, false, 'white']
      if (this.state === 0) {
        r = [true, true, 'white']
      }
      if (this.state === 1) {
        r = [true, false, 'white']
      }
      if (this.state === 2) {
        r = [false, true, 'white']
      }
      if (this.state === 3) {
        r = [false, true, 'purple']
      }
      this.enabled = r[0]
      this.indeterminate = r[1]
      this.color = r[2]
    }
  }
}
</script>
