var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-breadcrumbs',{staticClass:"py-2",attrs:{"items":[
      {
        text: 'Dashboard',
        disabled: false,
        to: { name: 'Dashboard' },
      },
      {
        text: "Chantier",
        disabled: false,
        to: _vm.loading
          ? {}
          : { name: 'SiteElevators', params: { id: _vm.elevator.site.id } },
      },
      {
        text: ("Ascenseur " + (_vm.loading ? '' : _vm.elevator.code)),
        disabled: false,
        to: _vm.loading
          ? {}
          : { name: 'ElevatorAudit', params: { id: _vm.elevator.id } },
      },
      {
        text: ("Edition de l'audit " + (_vm.$route.params.idAudit)),
        disabled: true,
      } ]}}),(_vm.loading)?_c('v-progress-linear',{staticClass:"mt-2",attrs:{"indeterminate":""}}):_vm._e(),(!_vm.loading)?_c('v-card',{staticClass:"mt-2"},[_c('v-card-text',[_c('div',[_c('div',[_c('v-icon',[_vm._v("mdi-checkbox-blank-outline")]),_c('span',[_vm._v(" = Non présent ")])],1),_c('div',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-checkbox-marked")]),_c('span',[_vm._v(" = A recycler ")])],1),_c('div',[_c('v-icon',{attrs:{"color":"purple"}},[_vm._v("mdi-checkbox-marked")]),_c('span',[_vm._v(" = A réemployer ")])],1)]),_c('v-divider',{staticClass:"my-5"}),_c('v-form',{on:{"submit":function($event){$event.preventDefault();_vm.dialogAgree = true}}},[_vm._l((_vm.wasteTypes),function(wasteType,i){return _c('div',{key:wasteType.id,staticClass:"mb-1",attrs:{"no-gutters":""}},[(i > 0)?_c('v-divider'):_vm._e(),_c('div',{staticClass:"mt-4 text-subtitle-2 font-weight"},[_vm._v(" "+_vm._s(wasteType.question)+" ")]),_c('div',{staticClass:"d-flex flex-wrap my-2"},[(wasteType.theoreticalWastes.length > 1)?_c('CustomCheckbox',{ref:'checkbox-all-' + wasteType.id,refInFor:true,staticClass:"mr-5",attrs:{"label":"Tout"},on:{"input":function($event){return _vm.changeAll(wasteType, $event)}}}):_vm._e(),_vm._l((wasteType.theoreticalWastes),function(theoreticalWaste){return _c('div',{key:theoreticalWaste.id,staticClass:"mr-5 d-flex flex-row"},[(_vm.answers[theoreticalWaste.id])?_c('CustomCheckbox',{ref:'checkbox-' + theoreticalWaste.id,refInFor:true,attrs:{"value":_vm.answers[theoreticalWaste.id].val},on:{"input":function($event){return _vm.onInputChange(
                    {
                      id: theoreticalWaste.id,
                      name: theoreticalWaste.name,
                    },
                    $event
                  )}}}):_vm._e(),(
                  _vm.answers[theoreticalWaste.id] &&
                  _vm.answers[theoreticalWaste.id].val == 2
                )?_c('v-icon',{staticClass:"mx-2",attrs:{"color":("" + (_vm.answers[theoreticalWaste.id].ref ? 'white' : 'error'))},on:{"click":function($event){return _vm.$refs.InfosAuditModal.open(
                    _vm.answers[theoreticalWaste.id],
                    theoreticalWaste.name,
                    theoreticalWaste.id
                  )}}},[_vm._v(" mdi-pencil ")]):_vm._e(),_c('span',{staticClass:"mt-2"},[_vm._v(_vm._s(theoreticalWaste.name))])],1)}),_c('v-btn',{staticClass:"align-self-end mb-1",attrs:{"small":""},on:{"click":function($event){return _vm.$refs.editForm.open(null, wasteType)}}},[_c('v-icon',{attrs:{"left":"","color":"white"}},[_vm._v(" mdi-plus-box ")]),_c('span',[_vm._v("Ajouter un déchet")])],1)],2)],1)}),_c('v-divider'),_c('v-layout',{attrs:{"justify-end":""}},[_c('v-btn',{staticClass:"mt-6",attrs:{"color":"success","type":"submit","loading":_vm.saveLoading}},[_vm._v(" Valider ")])],1)],2),_c('v-dialog',{attrs:{"max-width":"320"},model:{value:(_vm.dialogAgree),callback:function ($$v) {_vm.dialogAgree=$$v},expression:"dialogAgree"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Certifier le transfert des composants ")]),_c('v-card-text',[_vm._v("Le Maitre d'ouvrage certifie le transfert de propriété des composants d'ascenseurs récupérés lors du démontage de celui-ci.")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey darken-2","text":""},on:{"click":function($event){_vm.dialogAgree = false}}},[_vm._v(" Annuler ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v(" D'accord ")])],1)],1)],1)],1)],1):_vm._e(),_c('EditTheoreticalWaste',{ref:"editForm",attrs:{"default-type":_vm.wasteType},on:{"finished":_vm.fetchData}}),_c('InfosAuditModal',{ref:"InfosAuditModal",on:{"save":_vm.saveInfos}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }