<template>
  <div style="margin: 0px 5px 0px 5px;">
    <v-tooltip top style="position: relative;">
      <template v-slot:activator="{ on, attrs }">
          <v-btn 
            style="position: absolute; z-index: 3;"
            top
            left
            v-show="!image"
            v-bind="attrs"
            v-on="on"
            color="blue"
            :height="size"
            :width="size"
            @click="$refs.imgupload.click()"
            elevation="2"
          >
          <v-icon>
            mdi-camera-plus
          </v-icon>
          </v-btn>
          <v-avatar class="overflow-visible" :height="size" :width="size" rounded>
            <v-img v-if="image" :src="image">
            </v-img>
            <v-btn v-if="image" @click="removeImg" style="right: -10px; top: -10px;" absolute fab small color="error" height=24 width=24>
              <v-icon size=16 dark>
                mdi-close-thick
              </v-icon>
            </v-btn>
          </v-avatar>
      </template>
      <span>Ajouter une image</span>
    </v-tooltip>
    <input @change="previewImg($event)" :key="imgUploadKey" ref="imgupload" type="file" accept="image/png, image/jpeg" id="imgupload" style="display:none"/> 
  </div>
</template>

<script>
export default {
  components:{
    
  },
  props: {
    size:{
      type: String,
      default: "60"
    },
  },
  data () {
    return {
      imgUploadKey: 0,
      image: null,
    }
  },
  mounted() {
  },
  watch: {
  },
  computed: {
  },
  methods: {
    setImage(value){
      this.image = value;
    },
    removeImg(){
      this.imgUploadKey++;
      this.$emit('removeImg');
    },
    previewImg(value){
      if(value.target.files[0].size > 2097152){
       this.$store.commit('alert/add', {
          type: 'error',
          text: "L'image ne doit pas dépasser 2mo"
        })
       this.imgUploadKey++;
      }else{
        if(value && value.target.files[0]){
          this.$emit('addImage', value.target.files[0]);
        }
      }
    }
  },
}
</script>