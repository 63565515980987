<template>
  <v-container>
    <v-breadcrumbs
      class="py-2"
      :items="[
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'Dashboard' },
        },
        {
          text: `Chantier`,
          disabled: false,
          to: loading
            ? {}
            : { name: 'SiteElevators', params: { id: elevator.site.id } },
        },
        {
          text: `Ascenseur ${loading ? '' : elevator.code}`,
          disabled: false,
          to: loading
            ? {}
            : { name: 'ElevatorAudit', params: { id: elevator.id } },
        },
        {
          text: `Edition de l'audit ${$route.params.idAudit}`,
          disabled: true,
        },
      ]"
    />
    <v-progress-linear v-if="loading" class="mt-2" indeterminate />
    <v-card class="mt-2" v-if="!loading">
      <v-card-text>
        <div>
          <div>
            <v-icon>mdi-checkbox-blank-outline</v-icon>
            <span> = Non présent </span>
          </div>
          <div>
            <v-icon color="primary">mdi-checkbox-marked</v-icon>
            <span> = A recycler </span>
          </div>
          <div>
            <v-icon color="purple">mdi-checkbox-marked</v-icon>
            <span> = A réemployer </span>
          </div>
        </div>
        <v-divider class="my-5" />
        <v-form @submit.prevent="dialogAgree = true">
          <div
            no-gutters
            class="mb-1"
            v-for="(wasteType, i) in wasteTypes"
            :key="wasteType.id"
          >
            <v-divider v-if="i > 0"> </v-divider>
            <div class="mt-4 text-subtitle-2 font-weight">
              {{ wasteType.question }}
            </div>

            <div class="d-flex flex-wrap my-2">
              <CustomCheckbox
                v-if="wasteType.theoreticalWastes.length > 1"
                class="mr-5"
                label="Tout"
                :ref="'checkbox-all-' + wasteType.id"
                @input="changeAll(wasteType, $event)"
              />
              <div
                class="mr-5 d-flex flex-row"
                :key="theoreticalWaste.id"
                v-for="theoreticalWaste in wasteType.theoreticalWastes"
              >
                <CustomCheckbox
                  v-if="answers[theoreticalWaste.id]"
                  :value="answers[theoreticalWaste.id].val"
                  :ref="'checkbox-' + theoreticalWaste.id"
                  @input="
                    onInputChange(
                      {
                        id: theoreticalWaste.id,
                        name: theoreticalWaste.name,
                      },
                      $event
                    )
                  "
                />
                <v-icon
                  v-if="
                    answers[theoreticalWaste.id] &&
                    answers[theoreticalWaste.id].val == 2
                  "
                  :color="`${
                    answers[theoreticalWaste.id].ref ? 'white' : 'error'
                  }`"
                  class="mx-2"
                  @click="
                    $refs.InfosAuditModal.open(
                      answers[theoreticalWaste.id],
                      theoreticalWaste.name,
                      theoreticalWaste.id
                    )
                  "
                >
                  mdi-pencil
                </v-icon>
                <span class="mt-2">{{ theoreticalWaste.name }}</span>
              </div>
              <v-btn
                small
                @click="$refs.editForm.open(null, wasteType)"
                class="align-self-end mb-1"
              >
                <v-icon left color="white"> mdi-plus-box </v-icon>
                <span>Ajouter un déchet</span>
              </v-btn>
            </div>
          </div>
          <v-divider> </v-divider>
          <v-layout justify-end>
            <v-btn
              color="success"
              class="mt-6"
              type="submit"
              :loading="saveLoading"
            >
              Valider
            </v-btn>
          </v-layout>
        </v-form>
        <v-dialog v-model="dialogAgree" max-width="320">
          <v-card>
            <v-card-title class="text-h5">
              Certifier le transfert des composants
            </v-card-title>
            <v-card-text
              >Le Maitre d'ouvrage certifie le transfert de propriété des
              composants d'ascenseurs récupérés lors du démontage de
              celui-ci.</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey darken-2" text @click="dialogAgree = false">
                Annuler
              </v-btn>
              <v-btn color="green darken-1" text @click="onSubmit()">
                D'accord
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
    <EditTheoreticalWaste
      ref="editForm"
      :default-type="wasteType"
      @finished="fetchData"
    />
    <InfosAuditModal @save="saveInfos" ref="InfosAuditModal" />
  </v-container>
</template>

<script>
import ControlledCheckbox from "../../components/ControlledCheckbox.vue";
import CustomCheckbox from "@/components/CustomCheckbox";
import InfosAuditModal from "@/components/Audits/InfosAuditModal.vue";
import EditTheoreticalWaste from "@/components/TheoreticalWastes/EditTheoreticalWaste.vue";

import products from "./../../utils/products";

export default {
  components: {
    CustomCheckbox,
    EditTheoreticalWaste,
    ControlledCheckbox,
    InfosAuditModal,
  },
  data() {
    return {
      wasteTypes: [],
      questions: [],
      elevator: {},
      auditAnswers: [],
      answers: {},
      wastes: {},
      loading: true,
      saveLoading: false,
      dialogAgree: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      const products = await this.$store.dispatch(
        "products/getProductByAudit",
        this.$route.params.idAudit
      );
      console.log("Products : ", products);
      this.$store.dispatch("auditAnswers/fetchByAudit", this.$route.params.idAudit).then((res) => {
        this.auditAnswers = res;
        this.$store
          .dispatch("elevators/fetchOne", this.$route.params.id)
          .then((res) => {
            this.elevator = res;
            this.$store.dispatch("wasteTypes/fetchAllVanilla").then((res) => {
              this.wasteTypes = res;
              // Initialize answers objects
              res.forEach((el) => {
                el.theoreticalWastes.forEach(async ({ id }) => {
                  const auditAnswer = this.auditAnswers.find(
                    (x) => x.waste === `/api/theoretical_wastes/${id}`
                  );
                  const product = products.find(
                    (x) => x.theorical_waste.id === id
                  );
                  let image = null;
                  if (
                    product &&
                    product.Images &&
                    product.Images[0] &&
                    product.Images[0].url
                  ) {
                    console.log(product.Images[0].url);
                    let r = await fetch(product.Images[0].url);
                    let blob = await r.blob();
                    image = new File([blob], "name");
                  }
                  let val = 0;
                  if (auditAnswer) {
                    switch (auditAnswer.status) {
                      case "TAKEN_BY_SOCOBAT":
                        val = 1;
                        break;
                      case "TAKEN_BY_SOCOBAT_AND_REVALORIZED":
                        val = 2;
                        break;
                    }
                  }
                  this.$set(this.answers, id, {
                    val,
                    ref: product ? product.ref : null,
                    image,
                  });
                  console.log(this.answers);
                });
              });
              this.loading = false;
            });
          });
      });
    },
    saveInfos(item, id) {
      this.answers[id] = item;
      console.log(item);
    },
    onInputChange({ id, name }, val) {
      this.answers[id] = { val };
      // update the "all" checkbox if all the answer of a same type are checked
      if (val == 2) {
        this.answers[id] = { val, ref: "" };
        this.$refs.InfosAuditModal.open(this.answers[id], name, id);
      }
      // get the waste type
      const wasteType = this.wasteTypes.find((wasteType) =>
        wasteType.theoreticalWastes.find(
          (theoreticalWaste) => theoreticalWaste.id === id
        )
      );
      if (wasteType.theoreticalWastes.length <= 1) {
        return;
      }
      const ids = wasteType.theoreticalWastes.map(
        (theoreticalWaste) => theoreticalWaste.id
      );
      const ref = this.answers[ids[0]];
      if (ids.every((id) => this.answers[id] === ref)) {
        this.$refs["checkbox-all-" + wasteType.id][0].setValue(ref);
      }
    },
    changeAll(wasteType, val) {
      // get all the ids to put 1
      const ids = wasteType.theoreticalWastes.map((waste) => waste.id);
      // set all the ids to 1
      ids.forEach((id) => {
        this.answers[id] = { val };
        this.$refs["checkbox-" + id][0].setValue(val);
      });
    },
    async onSubmit() {
      console.log(this.answers);
      for (const [key, value] of Object.entries(this.answers)) {
        // ERROR
        if (value.val == 2 && !value.ref) {
          console.log(`${key}: ${value}`);
          console.log("Error !");
          this.$store.commit("alert/add", {
            color: "error",
            text: "Vous devez compléter toutes les références de vos déchets réemployés",
          });
          window.scrollTo(0, 0);
          this.dialogAgree = false;
          return;
        }
      }
      this.dialogAgree = false;
      this.saveLoading = true;
      const getStatus = (val) => {
        switch (val) {
          case 1:
            return "TAKEN_BY_SOCOBAT";
          case 2:
            return "TAKEN_BY_SOCOBAT_AND_REVALORIZED";
        }
      };
      let answers = Object.entries(this.answers)
        .filter(([key, val]) => val.val !== 0)
        .map(([key, val]) => ({
          status: getStatus(val.val),
          waste: "/api/theoretical_wastes/" + key,
          ref: val.ref,
          image: val.image,
        }));
      console.debug(JSON.parse(JSON.stringify(answers)));

      // Update audit answers
      let answersRep = await this.$store.dispatch("audits/updateAuditAnswers", {
        id: this.$route.params.idAudit,
        answers: answers.map((el) => ({
          ...el,
          waste: el.waste.replace("/api/theoretical_wastes/", ""),
        })),
      });
      console.log("Edit answers response : ", answersRep);

      // Delete products from audit id
      let res = await this.$store.dispatch(
        "products/deleteProductsByAudit",
        this.$route.params.idAudit
      );

      // Create products from revalorize theoretical_waste
      this.wastes = answers
        .map((val) => {
          console.log(val);
          if (val.status === "TAKEN_BY_SOCOBAT_AND_REVALORIZED") {
            return {
              id: val.waste.replace("/api/theoretical_wastes/", ""),
              ref: val.ref,
              image: val.image,
            };
          }
        })
        .filter((val) => {
          return val;
        });

      let finalWastes = [];
      console.log(this.wastes);
      this.wastes.forEach((waste) => {
        this.wasteTypes.forEach((el) => {
          el.theoreticalWastes.forEach((theo) => {
            if (waste.id == theo.id) {
              let id = waste.id;
              finalWastes.push({
                id: id,
                name: theo.name,
                ref: waste.ref,
                image: waste.image,
              });
            }
          });
        });
      });
      let ids = finalWastes.map((el) => el.id);
      let names = finalWastes.map((el) => el.name);
      let refs = finalWastes.map((el) => el.ref);
      let images = finalWastes.map((el) => el.image);
      let formData = new FormData();
      images.forEach((img) => {
        if (img) {
          formData.append("image[]", img);
        }
      });
      console.log({
        formData: formData,
        elevatorId: this.elevator.id,
        theoretical_waste: ids,
        name: names,
        ref: refs,
        image: images,
        auditId: this.$route.params.idAudit,
        userId: this.$store.state.auth.user.id,
        new: true,
      });
      this.$store
        .dispatch("products/registerProducts", {
          formData: formData,
          elevatorId: this.elevator.id,
          theoretical_waste: ids,
          name: names,
          ref: refs,
          image: images,
          auditId: this.$route.params.idAudit,
          userId: this.$store.state.auth.user.id,
          new: true,
        })
        .then(() => {
          console.log("SUCCESS");
          this.$store.commit("alert/add", {
            color: "success",
            text: "Les pièces revalorisées ont été ajoutées au catalogue !",
          });
          products.countNewProducts(this.$store);
        })
        .catch(() => {
          console.log("FAILED");
          this.$store.commit("alert/add", {
            color: "error",
            text: "Une erreur est survenue lors de l'ajout des pièces au catalogue",
          });
        })
        .finally(() => {
          this.saveLoading = false;
          this.$router.push({
            name: "ElevatorAudit",
            params: { id: this.elevator.id },
          });
        });
    },
  },
};
</script>
